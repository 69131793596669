.edit-client {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 32px 36px 36px 36px;
    width: 100%;
    height: 100%;

    .body-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        & > button {
            margin-top: auto;
        }
    }

    .form-content {
        h3 {
            border-bottom: 1px solid var(--color-grey-300);
        }

        .grid {
            width: 85%;
            grid-column-gap: 30px;
            padding: 10px 0px 10px 0px;

            label {
                font-family: "BebasNeue Bold";
                font-size: 16px;
                letter-spacing: 0.7px;
            }
        }

        button {
            width: 30%;
            margin-top: 20px;
        }
    }
}

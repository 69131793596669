.employee-details {
    padding: 32px 36px 36px 36px;
    width: 90%;

    & > div {
        width: 100%;
        grid-template-columns: 0.7fr 1.3fr;
        padding-bottom: 5px;
        grid-column-gap: 0;

        h3 {
            padding-bottom: 8px;
            margin: 0px 0px 20px 0px;
            border-bottom: 1px solid var(--color-grey-300);
        }

        label {
            font-family: "BebasNeue Bold";
            color: var(--font-color);
            font-style: normal;
            line-height: 24px;
            font-size: 20px;
            margin-top: 0px;
            letter-spacing: 0.7px;
        }

        p {
            margin-bottom: 16px;

            img {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                margin-right: 8px;
            }
        }
    }
}

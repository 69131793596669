.company-profile {
   padding: 88px 36px 36px 36px;
   display: grid;
   height: 100%;
   width: 90%;
   overflow: auto;

   .outline {
      margin-top: auto;
      font-family: "AvenirNext-Bold";
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 8px 10px;
      gap: 10px;
      border-radius: 10px;
      width: 12%;
      font-style: normal;
      line-height: 24px;
      font-size: 16px;
   }

   .contrast {
      text-decoration: none;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      padding: 12px 10px;
      gap: 10px;
      box-shadow: 0px 4px 8px var(--color-button-shadow);
      border-radius: 10px;
      font-weight: 600;
      font-style: normal;
      line-height: 24px;
      font-size: 16px;
   }
}

.edit-profile-grid {
    grid-template-columns: 0.6fr 1.3fr;
    grid-column-gap: 0;
    input {
        width: 60% !important;
    }

    label {
        font-family: "BebasNeue Bold";
        color: var(--font-color);
        font-style: normal;
        line-height: 24px;
        font-size: 20px;
        margin-top: 15px;
        letter-spacing: 0.7px;
    }
}

.edit-profile-grid-unset {
    grid-template-columns: unset;

    input {
        width: 20% !important;
    }

}

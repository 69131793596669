.create-project {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    width: 100%;
    height: 100%;

    h3 {
        border-bottom: 1px solid var(--color-grey-300);
    }

    input[type="text"] {
        width: 40%;
    }

    div {
        .button-container button {
            width: 20%;
            margin-top: 20px;
            background: var(--color-grey-750);
            border-color: var(--color-black);
            color: var(--color-white);
        }
    }

    details {
        width: 20%;
        margin-bottom: unset;
    }

    button {
        width: 20%;
        margin-top: 20px;
        background: var(--color-grey-750);
        border-color: var(--color-black);
        color: var(--color-white);
    }
}

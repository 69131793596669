.table-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        label {
            padding: 0px 8px 0px 0px;
            margin: unset;
        }

        input[type=checkbox] {
            margin-right: 75px;
        }

        input[type=text] {
            font-style: italic;
            color: var(--color-grey-250);
            box-shadow: 0px 4px 8px rgb(28 37 44 / 8%);
            width: 141px !important;
     
                &::placeholder {
                    color: var(--color-grey-250);                            
            }
        }
    }
}

.App {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  height: 100vh;

  .app-container {
    display: flex; 
    height:93%;
  }
}

.project-site {
   display: flex;
   flex-direction: column;
   padding: 16px 36px;
   width: 90%;

   .outline {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      flex-wrap: wrap;
      gap: 10px;
      font-family: "AvenirNext-Bold";
      font-style: normal;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      box-shadow: 0px 4px 8px var(--color-button-shadow);
      border-radius: 10px;
      margin-bottom: 16px;
      width: fit-content;
      padding: 12px 17px;
   }

   .table-action {
      input[type="checkbox"] {
         margin-right: 40px;
      }
   }

   .ongoing {
      background-color: var(--color-green-700);
      border-radius: 100px;
      padding: 5px 23px;
      font-style: normal;
      width: fit-content;
      font-weight: 600;
      font-size: 12px;
      color: var(--color-white);
      line-height: 14px;
   }

   .on_hold {
      @extend .ongoing;
      background-color: var(--color-orange-600);
   }

   .cancelled {
      @extend .ongoing;
      background: var(--color-red-700);
   }

   table {
      thead {
         tr {
            th {
               div {
                  display: inline;
               }

               details {
                  display: inline;
                  margin-left: 11px;
               }
            }
         }
      }

      tbody {
         tr {
            td:last-of-type {
               display: flex;
               justify-content: space-between;
            }
         }
      }
   }

   input[type="checkbox"] {
      border-radius: unset;
   }
}

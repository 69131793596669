.switcher {
  position: fixed;
  right: calc(var(--spacing) / 2 + var(--scrollbar-width, 0px));
  bottom: var(--spacing);
  width: auto;
  margin-bottom: 0;
  padding: 0.75rem;
  border-radius: 2rem;
  box-shadow: var(--card-box-shadow);
  line-height: 1;
  text-align: right;
  gap: 0;

  &::after {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 0.15rem solid currentColor;
    border-radius: 50%;
    background: linear-gradient(
      to right,
      currentColor 0,
      currentColor 50%,
      transparent 50%
    );
    content: "";
    vertical-align: bottom;
    transition: transform var(--transition);
  }

  &:focus {
    box-shadow: var(--card-box-shadow), 0 0 0 0.2rem var(--secondary-focus);
  }

  i {
    display: inline-block;
    max-width: 0;
    padding: 0;
    overflow: hidden;
    font-style: normal;
    font-size: 0.875rem;
    white-space: nowrap;
    font-family: "BebasNeue Bold";
    letter-spacing: 0.7px;
  }

  &:hover {
    i {
      max-width: 100%;
      padding: 0 calc(var(--spacing) / 2) 0 calc(var(--spacing) / 4);
      transition: max-width var(--transition), padding var(--transition);
    }

    &::after {
      transform: rotate(180deg);
    }
  }

  &:focus,
  &:hover {
    max-width: 100%;
    transition: background-color var(--transition),
      border-color var(--transition), color var(--transition),
      box-shadow var(--transition);
  }

}

@media (min-width: 576px) {
  .switcher {
    right: calc(var(--spacing) + var(--scrollbar-width, 0px));
  }
}



.site-details {
  padding: 36px;
  display: grid;
  height: 100%;
  width: 90%;
  overflow: auto;

  .grid {
    gap: 72px;

    .site-supervisor {
      display: flex;
      justify-content: space-between;
      padding-right: 7px;
      text-decoration: none;
      p{
        color: var(--font-color);
      }
    }

    .project-site-panel {
      label {
        font-family: "BebasNeue Bold";
        color: var(--font-color);
        font-style: normal;
        line-height: 24px;
        font-size: 16px;
        margin-top: 0px;
      }

      p {
        margin-bottom: 16px;

      }
      .ppe-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.75em;
        padding: .5em
      }
    }

    .details-panel {
      grid-template-columns: 0.7fr 1.3fr;
      padding-bottom: 5px;
      grid-column-gap: 0;

      & > div {
        width: 43%;
      }

      h3 {
        padding-bottom: 8px;
        margin: 0px 0px 20px 0px;
        border-bottom: 1px solid var(--color-grey-300);
      }

      label {
        font-family: "BebasNeue Bold";
        color: var(--font-color);
        font-style: normal;
        line-height: 24px;
        font-size: 20px;
        margin-top: 0px;
        letter-spacing: 0.7px;
      }

      p {
        margin-bottom: 16px;

        img {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }

      .view-file {
        font-family: "AvenirNext-Bold";
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 8px 10px;
        gap: 10px;
        font-style: normal;
        line-height: 24px;
        font-size: 16px;
      }

      .contrast {
        width: 57%;
        background: var(--color-grey-750);
        margin-top: 21px;
      }

      .status-tag {
        margin-top: 9px;
      }

      .ongoing {
        background-color: var(--color-green-700);
      }

      .on_hold {
        background-color: var(--color-orange-600);
      }

      .cancelled {
        background: var(--color-red-700);
      }
    }
  }

  .outline {
    font-family: "AvenirNext-Bold";
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 10px;
    gap: 10px;
    box-shadow: 0px 4px 8px var(--color-button-shadow);
    border-radius: 10px;
    font-style: normal;
    line-height: 24px;
    font-size: 16px;
    width: 12%;
    font-weight: 600;
    margin-top: auto;
  }
}

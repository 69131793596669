.activity-list-container {
    margin-bottom: 40px;
    border: 1px solid var(--color-grey-750);
    padding: 16px;
    border-radius: 5px;
    height: 224px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

    .tagLine {
        color: var(--color-grey-250);
        position: absolute;
        bottom: 9px;
        right: 11px;
        margin: unset;

        svg {
            margin-left: 6px;
        }
    }

    div {
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    h5 {
        border-bottom: 1px solid var(--color-grey-300);
        padding-bottom: 7px;

        svg {
            margin-right: 11px;
        }
    }

    label {
        margin-top: 16px;
    }

    a{
        color: var(--font-color);
    }

    p {
        margin-bottom: unset;
    }
}

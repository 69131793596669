.employee-details-container {
    padding: 32px 36px 36px 36px;

    .grid {
        grid-template-columns: 0.6fr 1.3fr;
        grid-column-gap: 0;

        div:first-of-type {
            p:nth-last-child(-n + 2) {
                margin-bottom: unset;
            }
        }

        h3 {
            padding-bottom: 8px;
            margin: 0px 0px 20px 0px;
            border-bottom: 1px solid var(--color-grey-300);
        }

        label {
            font-family: "BebasNeue Bold";
            color: var(--font-color);
            font-style: normal;
            line-height: 24px;
            font-size: 20px;
            margin-top: 0px;
            letter-spacing: 0.7px;
        }

        p {
            margin-bottom: 16px;
        }
    }

    .grid-unset {
        grid-template-columns: unset;
    }
}

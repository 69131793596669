.meat-ball {
    details[role=list] {
        display: inline;
        margin-bottom: unset;
        width: fit-content;

        summary {
            height: unset;
            border: unset !important;
            padding: 0px 25px;

            &:focus {
                box-shadow: unset !important;
            }
        }

        summary::after {
            display: none;
        }

        ul {
            left: -40px;
            right: unset;
            top: 46px;
            background: var(--color-white);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 5px;
            border: unset;
            padding: 20px 10px;
            user-select: none;

            h6 {
                padding: 0px 5px 20px 5px;
            }

            li:first-of-type {
                margin-top: unset;
            }

            li:last-of-type {
                margin-bottom: unset;
            }

            li {
                color: var(--color-black);
                cursor: pointer;
                padding: unset;
                padding-bottom: 10px;
                display: flex;
                flex-direction: row;

                label {
                    padding: 0px 23px;
                    display: flex;
                    align-items: center;
                }
            }

            .selected {
                background: var(--color-cyan--opacity);
                border-radius: 5px;
            }
        }
    }
}
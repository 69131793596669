.client-table {
    table {
        thead {
            tr {
                th:nth-child(4) {
                    text-align: center;
                }
            }
        }

        tbody {
            tr {
                cursor: pointer;

                td:first-of-type {
                    display: flex;
                    align-items: center;
                    font-weight: 700;

                    span {
                        margin-right: 5px;
                        width: 20px;
                        height: 20px;
                        
                        img {
                            border-radius: 50%;
                            width: 100%;
                        }
                    }
                }

                td:nth-child(4) {
                    text-align: center;
                }

                td:last-of-type {
                    text-align: right;
                    padding-right: 3px;
                    cursor: pointer;
                }
            }
        }
    }
}

.client {
   display: flex;
   flex-direction: column;
   padding: 16px 36px;
   width: 90%;

   .outline {
      font-family: "AvenirNext-Bold";
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 8px 10px;
      gap: 10px;
      box-shadow: 0px 4px 8px var(--color-button-shadow);
      border-radius: 10px;
      font-style: normal;
      line-height: 24px;
      font-size: 16px;
      width: 12%;
      font-weight: 600;
      align-self: flex-end;
      margin-bottom: 16px;
   }
}

.empty-list-container {
   display: flex;
   justify-content: center;
   align-items: center;
   padding-top: 20px;

   h1 {
      padding-left: 10px;
   }
   
}
.list-container {
    padding: 80px 36px 36px 36px;
    width: 90%;

    table {
        margin-top: 10px;

        thead {
            tr {
                th {
                    & div {
                        display: flex;
                        justify-content: flex-start;
                    }
                }

                th:nth-child(5) {
                    display: flex;
                    justify-content: center;
                }
            }
        }

        tbody {
            tr {
                cursor: pointer;
                
                td:nth-child(5) {
                    text-align: center;
                }

                td:first-of-type {
                    font-weight: 700;
                }

                td:last-of-type {
                    text-align: right;
                }
            }
        }
    }
}

.edit-project-site {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    h3 {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--color-grey-300);
    }

    input[type=text] {
        width:40%
    }

    div {
        .button-container button {
            width: 20%;
            margin-top: 20px;
            background: var(--color-grey-750);
            border-color: var(--color-black);
            color: var(--color-white);
        }
    }

    details {
        width: 20%;
        margin-bottom: unset !important;
    }

    button {
        width: 20%;
        margin-top: 20px;
    }

}

.file-picker {
    display: flex;
    flex-direction: column;
    width: 100% !important;

    div {
        flex-direction: column;

        & .img-container {
            width: 40%;
            height: 13vw;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--color-grey-250);
            margin-bottom: 20px;

            &.selected {
                background-color: unset;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        p {
            font-family: "BebasNeue Bold";
            font-size: 16px;
            letter-spacing: 0.7px;
        }

        .button-container {
            display: flex;
            flex-direction: row;

            .grey-out {
                background: var(--color-grey-50);
                color: var(--color-grey-250);
                border: none;
            }

            button {
                position: relative;
                margin-right: 10px;

                input {
                    position: absolute;
                    top: 0;
                    right: 0;
                    opacity: 0 !important;
                    cursor: pointer;
                }
            }
        }
    }
}

:root {
  --color-white: #ffffff;
  --color-cyan: #00aeff;
  --color-cyan--opacity: rgba(0, 174, 255, 0.1);
  --color-black: #231f20;
  --color-blue-200: #bee3f8;
  --color-grey-900: #191919;
  --color-grey-750: #414042;
  --color-grey-550: #6d6f71;
  --color-grey-300: #b9bdc0;
  --color-grey-250: #bcbec0;
  --color-grey-50: #f2f2f3;
  --color-blue-200: #bee3f8;
  --color-red-700: #c53030;
  --color-orange-500: #ed8936;
  --color-orange-600: #dd6b20;
  --color-green-700: #2f855a;
  --color-green-400: #68d391;
  --color-yellow-500: #ecc94b;
  --color-button-shadow: rgba(28, 37, 44, 0.08);
  --font-color: var(--color-black);
  --input-dark: #11191f;
  --login-bg: linear-gradient(180deg, #ffffff 5.62%, #bcbec0 100%);
  --contrast: hsl(205deg, 20%, 94%);
}

[data-theme=dark] {
  --font-color: var(--color-grey-250);
  --login-bg: linear-gradient(109.6deg, #232526 11.2%, #414345 78.9%);

  .outline {
    border-color: var(--contrast);
    color: var(--contrast);
    --font-color: var(--color-black);

    svg {
      fill: var(--color-white);
    }
  }

  .file-picker .button-container {
    .files {
      background-color: var(--color-white) !important;
      border-color: var(--contrast);
      color: var(--color-black);

      svg {
        fill: var(--color-black);
      }
    }
  }

  details[role="list"] summary:not([role]):focus {
    box-shadow: unset !important;
  }

  .contrast,
  .primary {
    border-color: var(--contrast);
    background-color: var(--contrast) !important;
    color: var(--color-black);
    --font-color: var(--color-black);

    svg {
      fill: var(--color-black);
    }
  }

  .profile-svg {
    svg {
      fill: var(--contrast);
    }
  }

  input[type="text"],
  input[type="email"] {
    background-color: var(--input-dark);
    border: 1px solid var(--color-white);
  }

  input[type="radio"] {
    background: var(--input-dark);

    &:hover {
      background: var(--color-cyan);
    }

    &:disabled {
      background-color: var(--input-dark);
      border-color: var(--color-white);
    }

    &:checked {
      background: var(--input-dark);
      border-color: var(--color-cyan);
      box-shadow: 0px 4px 8px var(--color-button-shadow);
      border-width: 0.35em;

      &:hover {
        background: var(--input-dark);
        border-color: var(--color-cyan);
      }

      &:disabled {
        background: var(--input-dark);
        border-color: var(--color-white);
      }
    }
  }

  input[type="checkbox"] {
    background-color: var(--input-dark);
    border: 1px solid var(--color-white);

    &:hover {
      border: 1.4px solid var(--color-cyan);
    }

    &:checked {
      border-color: var(--color-cyan);
      background-color: var(--color-cyan);

      &:disabled {
        border: 1px solid var(--color-white);
        --background-color: var(--color-black);
        --border-color: var(--color-black);
        opacity: 0.5px;
        pointer-events: none;
      }

      &:hover {
        background: var(--color-blue-200);
        background-image: var(--icon-checkbox);
        background-position: center;
        background-size: 0.75em auto;
        background-repeat: no-repeat;
      }
    }
  }
}

%font-properties {
  font-style: normal;
  line-height: 24px;
  font-size: 16px;
}

%padding-margin-unset {
  padding: unset;
  margin: unset;
}

@font-face {
  font-family: "Orbitron-Bold";
  font-weight: 800;
  src: url("./assets/fonts/Orbitron/Orbitron-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Orbitron-Medium";
  font-weight: 600;
  src: url("./assets/fonts/Orbitron/Orbitron-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Orbitron-Regular";
  src: url("./assets/fonts/Orbitron/Orbitron-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Orbitron-Black";
  src: url("./assets/fonts/Orbitron/Orbitron-Black.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-Bold";
  font-weight: 700;
  src: url("./assets/fonts/AvenirNext/AvenirNext-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-BoldItalic";
  src: url("./assets/fonts/AvenirNext/AvenirNext-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-DemiBold";
  src: url("./assets/fonts/AvenirNext/AvenirNext-DemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-DemiBoldItalic";
  font-weight: 700;
  src: url("./assets/fonts/AvenirNext/AvenirNext-DemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-Heavy";
  src: url("./assets/fonts/AvenirNext/AvenirNext-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-HeavyItalic";
  src: url("./assets/fonts/AvenirNext/AvenirNext-HeavyItalic.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-Italic";
  src: url("./assets/fonts/AvenirNext/AvenirNext-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-Medium";
  src: url("./assets/fonts/AvenirNext/AvenirNext-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-MediumItalic";
  src: url("./assets/fonts/AvenirNext/AvenirNext-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-Regular";
  font-weight: 500;
  src: url("./assets/fonts/AvenirNext/AvenirNext-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-UltraLight";
  src: url("./assets/fonts/AvenirNext/AvenirNext-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNext-UltraLightItalic";
  src: url("./assets/fonts/AvenirNext/AvenirNext-UltraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "BebasNeue Bold";
  src: url("./assets/fonts/BebasNeue/BebasNeue Bold.ttf") format("truetype");
}

h1 {
  font-family: "BebasNeue Bold";
  font-size: 36px;
  letter-spacing: 0.7px;
  font-style: normal;
  line-height: 40px;
  @extend %padding-margin-unset;
}

h2 {
  font-family: "BebasNeue Bold";
  font-style: normal;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.5px;
  @extend %padding-margin-unset;
}

h3 {
  font-family: "BebasNeue Bold";
  font-style: normal;
  font-size: 30px;
  line-height: 32px;
  @extend %padding-margin-unset;
}

h4 {
  font-family: "Orbitron-Medium";
  font-weight: 600;
  @extend %font-properties;
  margin: 0px;
  @extend %padding-margin-unset;
}

h5 {
  font-family: "BebasNeue Bold";
  font-size: 20px;
  @extend %font-properties;
  @extend %padding-margin-unset;
}

h6 {
  font-family: "BebasNeue Bold";
  font-size: 20px;
  @extend %font-properties;
  @extend %padding-margin-unset;
}

:where(input) {
  &[aria-invalid="true"] {
    border: 1px solid var(--color-red-700) !important;
    background-color: var(--color-red-700);

    &:is(:active, :focus) {
      --border-color: unset !important;
    }
  }

  &[aria-invalid="false"] {
    background-color: var(--color-green-700);
  }
}

p {
  font-family: "AvenirNext-Regular";
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0px;
  font-weight: 500;
}

button {
  font-family: "AvenirNext-Bold";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 10px;
  gap: 10px;
  box-shadow: 0px 4px 8px var(--color-button-shadow);
  border-radius: 10px;
  @extend %font-properties;
  width: 12%;
  font-weight: 600;
}

[data-theme=light] .outline {
  border: 1px solid var(--color-cyan);
  color: var(--font-color)
}

.secondary {
  background: var(--color-red-700);
  border: 1px solid var(--color-red-700);
  color: var(--color-white);
}

.contrast {
  margin-top: 20px;
  background: var(--color-grey-750);
  border-color: var(--color-black);
  color: var(--color-white);
}

.primary {
  background: var(--color-cyan);
  border: 1px solid var(--color-cyan);
  color: var(--color-black);
}

input[type="text"] {
  background: var(--color-white);
  border-radius: 5px;
  border: 1px solid var(--color-black);
  height: 40px !important;
  padding: 0px 16px !important;
  margin: unset !important;
  font-weight: 500;
  @extend p;

  &:active {
    border-color: var(--color-black);
  }
}

input[type="email"] {
  background: var(--color-white);
  border-radius: 5px;
  border: 1px solid var(--color-black);
  height: 40px !important;
  padding: 0px 16px !important;
  margin: unset !important;
  @extend p;
  font-weight: 500;

  &:active {
    border-color: var(--color-black);
  }
}

.input-error {
  border-color: var(--color-red-700);
}

input[type="checkbox"] {
  width: 32px;
  height: 32px;
  background: var(--color-white);
  border: 1.4px solid var(--color-black);

  &:hover {
    border: 1.4px solid var(--color-blue-200);
  }

  &:checked {
    border: 2px solid var(--color-white);
    background-color: var(--color-cyan);

    &:disabled {
      background-color: var(--color-grey-550);
    }

    &:hover {
      background: var(--color-blue-200);
      background-image: var(--icon-checkbox);
      background-position: center;
      background-size: 0.75em auto;
      background-repeat: no-repeat;
    }
  }
}

input[type="radio"] {
  height: 32px;
  width: 32px;
  background: var(--color-white);
  outline: auto;
  font-size: x-large;

  &:hover {
    background: var(--color-cyan);
  }

  &:disabled {
    background-color: var(--color-grey-50);
    border-color: var(--color-grey-550);
  }

  &:checked {
    background: var(--color-cyan);
    border-color: var(--color-white);
    box-shadow: 0px 4px 8px var(--color-button-shadow);

    &:hover {
      background: var(--color-white);
      border-color: var(--color-cyan);
    }

    &:disabled {
      background: var(--color-white);
      border-color: var(--color-grey-550);
    }
  }
}

input:disabled {
  border: 1px solid var(--color-grey-250);
}

.tagLine {
  font-family: "AvenirNext-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 8px;
  text-transform: uppercase;
  color: var(--color-grey-900);
}

.table-container {
  overflow: auto;
  height: 60%;
}

table {
  thead {
    background: var(--color-grey-750);
    position: sticky;
    top: 0;

    th {
      color: var(--color-white);
      font-weight: 600;
      @extend %font-properties;
      border-bottom: none;
      font-style: normal;
      font-size: 20px;
      line-height: 24px;
      font-family: "BebasNeue Bold";
      letter-spacing: 0.7px;

      div {
        align-items: center;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--color-grey-300);

      td {
        @extend p;
        border-bottom: unset;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
}

label {
  @extend p;
  margin-top: 6px;
}

a {
  @extend p;
}

select {
  border: 1px solid var(--color-black);
  height: 40px;
  padding: 0px 16px;
  margin: unset;
  @extend p;
  font-weight: 500;
}

.status-tag {
  padding: 5px 23px;
  border-radius: 100px;
  color: var(--color-white);
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  width: fit-content;
  text-align: center;
}

.text-grey {
  color: var(--color-grey-550);
  font-weight: 500 !important;
}

summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none !important;
  border-radius: 5px;
  border: 1px solid var(--color-black) !important;
  height: 40px !important;
  font-weight: 500;
  font-family: "AvenirNext-Regular";
  font-style: normal;
  font-size: 14px;
  line-height: 24px;

  a {
    cursor: pointer;
  }
}

article {
  box-shadow: none;
  background: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

small {
  margin-top: unset;
  margin-bottom: unset;
  color: var(--color-red-700);
  font-size: 12px;
}

.Toastify__toast {
  font-size: 14px;
  color: var(--font-color) !important;
}

.body-container {
  overflow-y: auto;
  width: 90%;
  height: 100%;
}

[data-tooltip]:not(a, button, input) {
  border-bottom: none;
  text-decoration: none;
  cursor: default;
}
.nav-container {
    width: 10%;
    background: var(--color-grey-750);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    ul {
        padding: 10px;

        li {
            a {
                display: flex;
                align-items: center;

                p {
                    color: var(--color-grey-250);
                    padding-left: 10px;
                }

                svg {
                    fill: var(--color-grey-250);
                }

                &.active {
                    border-right: 4px solid #00aeff;
                    border-radius: unset;

                    p {
                        color: var(--color-white);
                        font-weight: 600;
                    }

                    svg {
                        fill: var(--color-white);
                    }
                }

                &:focus {
                    background-color: unset;
                }
            }
        }
    }
    .navbar-footer {
        text-align: left;
        padding: 0px 10px;
        margin-top: auto;
        
        button {
            width: 100%;
        }
    }
}

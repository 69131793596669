.edit-profile-container {
    padding: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h3 {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--color-grey-300);
    }

    button {
        margin-top: auto;
        margin-bottom: 36px;
    }
}

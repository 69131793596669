.invite-user {
    display: flex;
    flex-direction: column;
    height: 100%;
    width:100%;
    overflow: auto;

    .grid {
        grid-template-columns: 0.25fr 0.25fr;

        div {
            margin-top: 6px;
        }

        label {
            font-family: "BebasNeue Bold";
            font-size: 16px;
            letter-spacing: 0.7px;
        }
    }

    h3 {
        margin-bottom: 15px;
        border-bottom: 1px solid var(--color-grey-300);
    }

    .primary {
        margin-top: auto;
        // margin-bottom: unset;
    }
}

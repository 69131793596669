.header-container {
    height: 7%;
    background: var(--color-cyan);
    padding:  16px;
    grid-template-columns: 1.3fr 1.4fr 1.3fr;

    h2 {
        margin-bottom: 0px;
        text-align: center;
        color : var(--color-white)
    }
}

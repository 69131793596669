.client-details {
   padding: 32px 36px 36px 36px;
   display: grid;
   height: 100%;
   width: 100%;

   .client-footer {
      display: flex;
      align-items: flex-end;
      padding-bottom: 36px;

      .outline {
         font-family: "AvenirNext-Bold";
         display: flex;
         justify-content: center;
         align-items: center;
         flex-wrap: wrap;
         padding: 8px 10px;
         gap: 10px;
         box-shadow: 0px 4px 8px var(--color-button-shadow);
         border-radius: 10px;
         font-style: normal;
         line-height: 24px;
         font-size: 16px;
         width: 12%;
         font-weight: 600;
      }

      .secondary {
         margin-left: 5px;
         margin-bottom: unset;
      }
   }
}

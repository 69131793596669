.accept-invitation {
    background: var(--login-bg);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
        padding-bottom: 30px;
    }

    button {
        width: 21%;
        background-color: var(--color-white) ;
        margin-top: 30px;

        h6 {
            padding-left: 5px;
        }    
    }
}

.review-container {
    padding: 20px;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;

    h3 {
        padding-bottom: 8px;
        margin-bottom: 15px;
        border-bottom: 1px solid var(--color-grey-300);
    }

    label {
        padding-top: 15px;
        font-family: "BebasNeue Bold";
        color: var(--font-color);
        font-style: normal;
        line-height: 24px;
        font-size: 20px;
        letter-spacing: 0.7px;
    }

    a {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        p {
            color: var(--font-color);
        }
    }

    .body-container {
        display: grid;

        div {
            display: grid;
            grid-template-columns: 0.8fr 1.7fr 1.5fr;
            gap: 10px;

            div {
                display: unset;
                width: fit-content;

                div {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                }

                span {
                    display: flex;
                    flex-direction: row;

                    p {
                        padding-right: 5px;
                        font-weight: 600;
                    }
                }

            }

            .icon-container {
                padding-top: 20px;
                display: grid;
                grid-template-columns: auto auto auto;
                grid-column-gap: 20px;
                grid-gap: 20px;
                width: fit-content;

                input[type="checkbox"] {
                    &:disabled {
                        background-color: var(--color-cyan);
                        opacity: unset;
                    }
                }
                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-top: 5px;

                    input {
                        margin-top: 5px;
                    }
                }
            }
        }

        hr {
            border-top: 1px solid var(--color-grey-300);
        }
        button {
            margin-top: auto;
            color: (--color-black);
            width: 14%;
        }
    }
}

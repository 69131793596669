.login-container {
    background: var(--login-bg);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:110%;

    .app-container {
        height:100% !important;
    }

    img {
        margin-bottom: 20px;
    }

    button {
        width: 21%;
        background-color: var(--color-white);
        color: var(--color-black);

        h6 {
            padding-left: 5px;
        }
    }
}

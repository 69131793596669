.company-details {
   h3 {
      padding-bottom: 8px;
      margin-bottom: 15px;
      border-bottom: 1px solid var(--color-grey-300);
   }

   label {
      font-family: "BebasNeue Bold";
      color: var(--font-color);
      font-style: normal;
      line-height: 24px;
      font-size: 20px;
      margin-top: 0px;
      letter-spacing: 0.7px;
   }

   .company-logo {
      article {
         width: 44%;
         margin-top: 5px;
      }
   }

   .details-panel {
      p {
         margin-bottom: 15px;
      }
   }
}

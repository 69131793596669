.create-client {
    display: grid;
    grid-template-rows: 0.1fr 1fr 0.1fr;
    padding: 32px 36px 36px 36px;
    width: 100%;
    height: 100%;

    .body-content {
        h3 {
            border-bottom: 1px solid var(--color-grey-300);
        }

        .grid {
            width: 85%;
            grid-column-gap: 30px;
            padding: 10px 0px 10px 0px;

            label {
                font-family: "BebasNeue Bold";
                font-size: 16px;
                letter-spacing: 0.7px;
            }
        }

        button {
            width: 30%;
            margin-top: 20px;
        }
        
    }

    button:last-of-type {
        margin-top: auto;
    }
}

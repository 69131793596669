.swms-container {
    display: flex;
    flex-direction: column;
    padding: 50px 36px;
    width: 90%;

    table {

        tbody {
            tr {
                td:first-of-type {
                    display: flex;
                    align-items: center;
                    font-weight: 700;

                    img {
                        margin-right: 5px;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                    }
                }

                .status-tag {

                    &.reviewed {
                        background-color: var(--color-cyan);
                    }

                    &.review {
                        background-color: var(--color-orange-500);
                    }
                }

                td:last-of-type {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    svg {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    input[type=checkbox] {
        border-radius: unset;
    }
}

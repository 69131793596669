.filter-container {
    display: inline;
    
    .details-container {
        display: inline;
        margin-bottom: unset;
        width: fit-content;

        .summary-container {
            height: unset;
            padding: unset;
            border: unset !important;
            margin-left: 5px;
        }
        
        summary::after {
            display: none;
        }

        ul {
            left: -58px;
            right: unset;
            top: 29px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 5px;
            border: unset;
            padding: 8px;
            user-select: none;

            li {
                color: var(--font-color);
                cursor: pointer;
                padding: unset;
                padding: 8px 16px;
                margin-bottom: unset !important;
                margin-top: unset !important;
            }

            .selected {
                background: var(--color-cyan--opacity);
                border-radius: 5px;
            }
        }
    }    
}

.back-btn {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 10px;
    cursor: pointer;
    justify-content: flex-start;
    width: fit-content;
    text-decoration: none;
    color: var(--font-color);
    font-weight: 500;
}

.profile-container {
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .grid-container {
        display: grid;
        grid-template-columns: 0.6fr 1.3fr;
    }

    div {
        display: unset;

        h3 {
            padding-top: 50px;
            padding-bottom: 10px;
            border-bottom: 1px solid var(--color-grey-300);
        }

        img {
            width: 25px;
            height: 25px;
        }

        label {
            font-family: "BebasNeue Bold";
            color: var(--font-color);
            font-style: normal;
            line-height: 24px;
            font-size: 20px;
            margin-top: 15px;
            letter-spacing: 0.7px;
        }
    }

    button {
        margin-top: auto;
        justify-content: flex-start;
    }

    .outline {
        margin-top: auto;
        margin-bottom: 36px;
        font-family: "AvenirNext-Bold";
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 8px 10px;
        gap: 10px;
        box-shadow: 0px 4px 8px var(--color-button-shadow);
        border-radius: 10px;
        font-style: normal;
        line-height: 24px;
        font-size: 16px;
        width: 12%;
        font-weight: 600;
    }
}

.project-site-form {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .inputs {
        grid-column: 1;
        display: flex;
        flex-direction: column;
    }

    label {
        font-family: "BebasNeue Bold";
        font-size: 16px;
        letter-spacing: 0.7px;
    }

    .ppe-section {
        grid-column: 2;
        grid-row: 1/-1;
        display: flex;
        flex-direction: column;

        .icon-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            gap: 1em;
            
            div {
                opacity: 0.3;
                cursor: pointer;

                svg {
                    pointer-events: none;
                }

                &.selected {
                    opacity: 1;
                }
            }
        }
    }
}
.employee-list {
    width: 90%;

    div:first-of-type {
        margin-bottom: 47px;
    }

    div a {
        font-weight: unset;
        border-bottom: 1px solid var(--color-grey-300);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0px;
        text-decoration: none;

        p {
            word-break: break-all;
            width: 50%;
            color: var(--font-color);
        }
    }

    .invited-users {
        margin-top: 30px;

        .users-wrapper {
            display: grid;
            border-bottom: 1px solid var(--color-grey-300);
            padding: 8px 0px;
            grid-template-columns: 2fr 3fr 1.5fr 1fr;
            gap: 0.5rem;

            span {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .no-invites {
            font-size: 16px;
            font-weight: 600;
            padding-top: 10px;
        }
    }
}